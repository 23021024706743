<template>
  <div class="member-shop-parameters">
    <PageTitle
      title="會員商城設定"
      icon="chevron_left"
      hideBtn
      style="margin-bottom: 64px"
      @iconClick="$router.push({ name: 'Parameters' })"
    />
    <div class="parameters-category-list">
      <CategoryBlock
        v-for="param in memberShopParametersRoutes[0].children"
        :key="param.name"
        :data="param"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue'
import PageTitle from '@/components/Title/PageTitle'
import CategoryBlock from '../components/CategoryBlock.vue'
import store from '@/store'

export default defineComponent({
  name: 'MemberShopParameters',
  components: { PageTitle, CategoryBlock },
  setup (props) {
    const asyncRoutes = computed(() => store.getters.asyncRoutes)

    const paramsRoutes = computed(() => {
      return asyncRoutes.value.filter(route => route.name === 'Parameters')
    })

    const memberShopParametersRoutes = computed(() => {
      if (paramsRoutes.value) {
        if (!paramsRoutes.value.length) return []
      }
      return paramsRoutes.value[0].children.filter(route => route.name === 'MemberShopParameterSetting')
    })
    return {
      memberShopParametersRoutes,
    }
  },
})
</script>

<style scoped lang="postcss">
.parameters-category-list {
  @apply grid grid-cols-3 gap-[44px];
}

@media screen and (max-width: 1200px) {
  .parameters-category-list {
    @apply grid grid-cols-2;
  }
}
</style>
